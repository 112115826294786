//  ======================================================
//  ERRORPAGE.CSS
//  errorpage stylesheet.
//  ======================================================

@import 'functions';
@import 'css3-mixins';
@import 'mixins';
@import 'settings';

body {
	background: white !important;
}

#uber {
	width: $container-width;
	max-width: 800px;
	margin: 0 auto;
	padding: 100px 15px;
	min-height: 500px;
	text-align: center;

	h1 {
		font-size: 22px;
		font-weight: 700;
		margin-top: 20px;
		margin-bottom: 10px;

		& + * {
			margin-top: 0;
		}
	}

	@media (min-width: $screen-sm) {
		padding: 130px 300px 0 15px;
		background: url(../images/error/errorImage.jpg) right center no-repeat;
		text-align: left;
	}
}

#logo{
	display: block;
}

#logo img {
	width: 243px;
	max-width: 100%;
	margin-bottom: 20px;
}

#logo a:focus img {
	outline: 1px solid $link-color;
}